import React, { Component } from 'react';

export default class contactus extends Component {
  render() {
    return (
      <section id="contact">
        <div className="container wow fadeInUp">
          <div className="section-header">
            <h3 className="section-title" style={{fontFamily:'WalkBold'}}>ContÁctanos</h3>
            <p className="section-description">
              Siempre nos podrás contactar por nuestro servicio de email y estaremos para ti en un momento</p>
          </div>
        </div>    
        <div className="container wow fadeInUp">
          <div className="row justify-content-center">    
            <div className="col-lg-3 col-md-4">   
              <div className="info">
                <div>
                  <i className="fa fa-map-marker"></i>
                  <p>Tomas Bermur N39-350 y Francisco Urrutia<br />Quito – Ecuador</p>
                </div>    
                <div>
                  <i className="fa fa-envelope"></i>
                  <p>info@fyt.ec</p>
                </div>    
                <div>
                  <i className="fa fa-phone"></i>
                  <p>0985347055 / 0993434954</p>
                </div>
              </div>    
              <div className="social-links">
                <a href="https://twitter.com/fyt_ec" 
                className="twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                <a href="https://www.facebook.com/FT-101023238274542/?view_public_for=101023238274542" 
                className="facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                <a href="https://www.instagram.com/fyt_ec/" 
                className="instagram" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                <a href="https://www.linkedin.com/company/fyt-e/?viewAsMember=true" 
                className="linkedin" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
              </div>    
            </div>    
            <div className="col-lg-5 col-md-8">
              <div className="form">
                <div id="sendmessage">Tu mensaje ha sido enviado con exito. Gracias!</div>
                <div id="errormessage"></div>
                <form method="post" className="contactForm">
                  <div className="form-group">
                    <input type="text" name="name" className="form-control" id="name" 
                    placeholder="Tu nombre" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                    <div className="validation"></div>
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" name="email" id="email" 
                    placeholder="Tu Email" data-rule="email" data-msg="Please enter a valid email" />
                    <div className="validation"></div>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="subject" 
                    placeholder="Título" data-rule="minlen:4" data-msg="Por favor ingresa al menos 8 caracteres" />
                    <div className="validation"></div>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control" name="message" rows="5" data-rule="required" 
                    data-msg="Please write something for us" placeholder="Mensaje"></textarea>
                    <div className="validation"></div>
                  </div>
                  <div className="text-center"><button type="submit">Enviar Mensaje</button></div>
                </form>
              </div>
            </div>    
          </div>    
        </div>
      </section>
    )
  }
}
