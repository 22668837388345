export const scrollFunction = (n) => {
  window.addEventListener('scroll', function(){
    var nvmenua = document.getElementsByClassName('nav-menu')[0].children;
    var headerMain = document.getElementById('header');
    var headerMainImg = document.getElementById('headerLogoImg');
    var anav;
    if(this.scrollY > 100){
      headerMain.classList.add('header-fixed');
      if(window.location.pathname === '/'){
        headerMain.removeAttribute('style');
        headerMainImg.children[0].src = 'https://www.fyt.ec/images/logo.png';
      }
      headerMain.setAttribute('data', '0')
      for (let a = 0; a < nvmenua.length; a++) {
        const aMenuColor = nvmenua[a];
        anav = aMenuColor.children[0];
        anav.style.color = 'rgb(130, 130, 130)'
      }
    }else{
      headerMain.classList.remove('header-fixed');
      headerMain.setAttribute('data', '1');
      if(window.location.pathname === '/'){
        headerMain.setAttribute('style', 'background:rgba(0, 0, 0, 0.5)');
        headerMainImg.children[0].src = 'https://www.fyt.ec/images/logonegro.png';
      }
      for (let a = 0; a < nvmenua.length; a++) {
        const aMenuColor = nvmenua[a];
        anav = aMenuColor.children[0];
        if(n === 1){
          anav.style.color = 'white'
        }
      }
    }

    //SCROLL FUNCTION ABOUT US
    /*var iconBox = document.getElementsByClassName('icon-box');
    document.getElementById('picAboutUs').style.opacity = '0';
    for (let eleBox = 0; eleBox < iconBox.length; eleBox++) {
      const element = iconBox[eleBox];
      element.style.opacity = '0';
    }
    if(this.scrollY > 180){
      document.getElementById('picAboutUs').classList.add('fadeInRight');
      document.getElementById('picAboutUs').style.opacity = '1';
    }else{
      document.getElementById('picAboutUs').classList.remove('fadeInRight');
      document.getElementById('picAboutUs').style.opacity = '0';
    }
    if(this.scrollY > 250){
      iconBox[0].classList.add('fadeInUp');
      iconBox[0].style.opacity = '1';
    }
    if(this.scrollY > 440){
      iconBox[1].classList.add('fadeInUp');
      iconBox[1].style.opacity = '1';
    }else{
      iconBox[1].classList.remove('fadeInUp');
      iconBox[1].style.opacity = '0';
    }
    if(this.scrollY > 560){
      iconBox[2].classList.add('fadeInUp');
      iconBox[2].style.opacity = '1';
    }else{
      iconBox[2].classList.remove('fadeInUp');
      iconBox[2].style.opacity = '0';
    }*/

  });
}

export const portofolioFunction = () => {
  const portafolio = document.getElementById('portfolio-flters').children;
  for (let p = 0; p < portafolio.length; p++) {
    const elementP = portafolio[p];
    elementP.addEventListener('click', function(){
      for (let e = 0; e < portafolio.length; e++) {
        const element = portafolio[e];
        element.classList.remove('filter-active')
      }
      this.classList.add('filter-active');
      var selectedFilter = this.getAttribute('data-filter').substring(1);

      var porItem = document.getElementsByClassName("portfolio-item");
      for (let i = 0; i < porItem.length; i++) {
        const porItemR = porItem[i];
        porItemR.style.transform = 'scale(0)';
        porItemR.style.position = 'absolute';
      }

      setTimeout(function() {
        var fi = document.getElementsByClassName(`${selectedFilter}`);
        var str =  'Fishing-Services, .Milling-Services, .Down-Hole-Camera, .Cutting-and-Slot-Recovery, .Wellbore-Clean-Up, .Pulling-and-Running-Services, .Drilling-Impulse, .Whipstock-Services, .Casing-repair'

        if(selectedFilter === str){
          for (let i = 0; i < porItem.length; i++) {
            const porItemR = porItem[i];
            porItemR.style.transform = 'scale(1)';
            porItemR.style.position = 'relative';
          }
        }else{
          for (let f = 0; f < fi.length; f++) {
            const filter = fi[f];
            filter.style.transform = 'scale(1)';
            filter.style.position = 'relative';
          }
        }
      }, 300);
    });
  }
}

export const inputQueryLogin = () => {
  const inputs = document.querySelectorAll('.inputLogin');

  function addcl(){
    let parent = this.parentNode.parentNode;
    parent.classList.add("focus");
  }
  function remcl(){
    let parent = this.parentNode.parentNode;
    if(this.value === ""){
      parent.classList.remove("focus");
    }
  }
  inputs.forEach(input => {
    input.addEventListener("focus", addcl);
    input.addEventListener("blur", remcl);
  });
}

export const profileNav = () => {
  const ulNav = document.getElementsByClassName('navItemPro');
  const dashNavUserProfile = document.getElementById('UserProfile').getAttribute('class');
  const dashNavCreateItem = document.getElementById('createItem').getAttribute('class');
  const userprofile = document.getElementById('UserProfile');
  const itemCreate = document.getElementById('createItem');
  for (let Li = 0; Li < ulNav.length; Li++) {
    const ulNavLi = ulNav[Li];
    ulNavLi.addEventListener('click', function(){
      const uniqueUlNavLiData = this.getAttribute('data');
      if(uniqueUlNavLiData === dashNavUserProfile){
        userprofile.style.transition = '0.5s linear';
        itemCreate.style.transition = '0.5s linear';
        setTimeout(() => {
          userprofile.style.opacity = '1';
          itemCreate.style.opacity = '0';
          itemCreate.style.transform = 'translateX(-100%)'
          userprofile.style.transform = 'translateX(0%)';
        }, 200);
        userprofile.style.display = 'block';
        itemCreate.style.display = 'none';
        this.children[0].classList.add('activeItem');
        document.getElementById('createItemId').classList.remove('activeItem');
        document.getElementById('TitleLogo').innerHTML = '<i class="fas fa-tachometer-alt"></i> Dashboard'
      }else if(uniqueUlNavLiData === dashNavCreateItem){
        userprofile.style.transition = '0.5s linear';
        itemCreate.style.transition = '0.5s linear';
        setTimeout(() => {
          userprofile.style.opacity = '0';
          itemCreate.style.opacity = '1';
          itemCreate.style.transform = 'translateX(0%)';
          userprofile.style.transform = 'translateX(-100%)';
        }, 200);
        userprofile.style.display = 'none';
        itemCreate.style.display = 'block';
        this.children[0].classList.add('activeItem');
        document.getElementById('userProfile').classList.remove('activeItem');
        document.getElementById('TitleLogo').innerHTML = '<i class="fas fa-pencil-alt"></i> Crear Artículo'
      }
    });
  }
}

export const compressImage = (e) => {}