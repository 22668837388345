import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { scrollFunction } from '../../utils/js/main'
import Logo from '../Assets/logo.png';
import LogoAlt from '../Assets/logonegro.png';
import profileL from '../Assets/SVG/engineer.svg';
import './navbar.css';

class navbar extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      rgbc: '',
      isAuthenticated: false
    }
  }
  

  componentDidMount(){
    var headerMain = document.getElementById('header');
    if(this.props.rgbcolor.path === '/'){
      headerMain.style.background = 'rgba(0, 0, 0, 0.5)';
    }
    scrollFunction(this.props.rgbcolor.color);
    this.setState({
      rgbc: this.props.rgbcolor.color
    })
  }

  static getDerivedStateFromProps(nextProps, state){
    if(nextProps.auth.isAuthenticated !== state.isAuthenticated){
      return{
        isAuthenticated: nextProps.auth.isAuthenticated
      }
    }else{
      return null
    }
  }

  changeLogo = () => {
    let header;
    
    if(this.props.rgbcolor.path === '/'){
      header = (<a href="/" id='headerLogoImg' data='logoImg'>
        <img src={LogoAlt} alt="logoFyT" style={{width:'155px',height:'55px',marginTop:'-14px'}}/></a>)
    }else{
      header = (<a href="/" id='headerLogoImg' data='logoImg'>
        <img src={Logo} alt="logoFyT" style={{width:'155px',height:'55px',marginTop:'-14px'}}/></a>)
    }
    
    return header
  }

  render() {
    const { rgbc, isAuthenticated} = this.state;
    return (
      <header id="header" data='1' >
        <div className="container">
          <div id="logo" className="pull-left">
            {this.changeLogo()}
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li className="menu-active"><a href="/#hero" 
              style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Productos & Servicios</a></li>
              <li><a href="https://www.fyt.ec/#about" style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Nosotros</a></li>
              <li><a href="https://www.fyt.ec/#team" style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Equipo</a></li>
              <li className="menu-has-children"><a href="/" onClick={(e) => e.preventDefault()} 
              style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Compañia</a>
                <ul>
                  <li><a href="/fyt/newsroom">Artículos</a></li>
                  <li><a href="/fyt/energy">Data Sheets</a></li>
                  <li><a href="/fyt/sheets">Technicals papers</a></li>
                </ul>
              </li>
              <li><a href="https://www.fyt.ec/#contact" style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Contacto</a></li>
              {isAuthenticated ?
              <li><a href="/fyt/profile" 
              style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>
                <img src={profileL} alt='profileFace' style={{width:'25px',height:'25px',borderRadius:'25px',marginTop:'-8px'}} />
              </a></li>
              :
              <li><a href="/fyt/login" 
              style={{color:(rgbc === 1 ? 'white' : 'rgb(130, 130, 130)') }}>Login</a></li>
              }
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

navbar.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(navbar)