import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import lfarrow from '../../Assets/SVG/unlineIcon/reply.svg'; import rtarrow from '../../Assets/SVG/unlineIcon/forward.svg';
import boldF from '../../Assets/SVG/unlineIcon/bold.svg'; import italicF from '../../Assets/SVG/unlineIcon/italic.svg';
import strickeT from '../../Assets/SVG/unlineIcon/strikethrough.svg'; import rFormat from '../../Assets/SVG/unlineIcon/remove-text.svg';
import numberedList from '../../Assets/SVG/unlineIcon/list.svg'; import bList from '../../Assets/SVG/unlineIcon/bullet-list.svg';
import dindent from '../../Assets/SVG/unlineIcon/indent-decrease-symbol.svg'; 
import iIndent from '../../Assets/SVG/unlineIcon/indent-increase-interface-symbol.svg';
import quote from '../../Assets/SVG/unlineIcon/quotes-right.svg'; import link from '../../Assets/SVG/unlineIcon/link.svg';
import unlink from '../../Assets/SVG/unlineIcon/chain-broken.svg'; import image from '../../Assets/SVG/unlineIcon/image.svg';
import insertM from '../../Assets/SVG/unlineIcon/insert-template.svg'; import table from '../../Assets/SVG/unlineIcon/table2.svg';
import emoji from '../../Assets/SVG/unlineIcon/smile.svg'; import maximize from '../../Assets/SVG/unlineIcon/enlarge.svg';

export default class createItem extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      title: '',
      desc: '',
      date: '',
      category: 'Articulo',
      subCategory: 'Fishing Services',
      file: null,
      filePdf: null,
      btnActive: 'on'
    }
  }

  onImageControl = () => {
    var img = 'https://fyt.ec/images/p.jpg';
    document.execCommand('insertImage', false, img);
    var imagen = document.querySelector("img[src='" + img + "']");
    if(imagen === null){
      return;
    }else{
      imagen.style.width = "500px";
      imagen.style.height = "800px";
      imagen.style.float = 'left';
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  fileChangedHandler = event => {
    this.setState({ file: event.target.files[0] })
  }

  fileChangedHandlerPdf = event => {
    this.setState({ filePdf: event.target.files[0] })
  }

  onSubmit = () => {
    const {title, desc, date, category, subCategory, file, filePdf} = this.state;
    const editorDesc = document.getElementById('fyt_editor').innerHTML;

    let newBlog = {
      title: title,
      description: desc,
      date: date,
      category: category,
      subcategory: subCategory,
      blog: editorDesc
    }

    const formData = new FormData();
    formData.append('blog', file);

    const formDataPdf = new FormData();
    formDataPdf.append('pdf', filePdf)

    axios.post(`/api/blog/add/image/blog/blogs`, formData)
      .then(res => {
        if(res.status === 200){
          axios.post('/api/blog/add/pdf/blog/pdf', formDataPdf)
            .then(res => {
              if(res.status === 200){
                axios.post(`/api/blog/add/blog`, newBlog)
                  .then(res => {
                    this.setState({
                      btnActive: 'off'
                    });
                    if(res.status === 200){
                      this.setState({
                        btnActive: 'on'
                      })
                      swal("Excelente trabajo", "Excelente trabajo, tu blog se ha guardado en el sistema", "success")
                        .then(() => {
                          window.location.href = `/fyt/profile`
                        })
                    }else{
                      swal("Attention", "There was an internal problem. Communicate with support.", "error")
                    }
                  })
                  .catch(err => console.log(err));
              }
            })
        }
      })

  }
  
  render() {
    const {file, btnActive, filePdf} = this.state;
    return (
      <div className='container2'>
        <div className='row'>
          <div className='col-sm-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Creación de portada de artículo. Aquí vamos a crear un resumen del artículo.
                </li>
              </ol>
            </nav>
          </div>
          <div className='col-sm-12'>
            <div className="form-group">
              <label className='ml-1' htmlFor="title">Título</label>
              <input className="form-control w-100" id="title" name='title' onChange={this.onChange} />
            </div>
            <div className="form-group">
              <label className='ml-1' htmlFor="Description">Descripción</label>
              <input className="form-control w-100" id="Description" name='desc' onChange={this.onChange} />
            </div>
            <div className="form-group">
              <label className='ml-1' htmlFor="date">Fecha</label>
              <input className="form-control w-100" id="date" name='date' onChange={this.onChange} />
            </div>
            <div className="form-group">
              <label className='ml-1'>Categoria</label>
              <select className="custom-select" name='category' onChange={this.onChange}>
                <option>Selecciona Categoria</option>
                <option>Articulo</option>
                <option>Hoja de datos</option>
                <option>Papeles tecnicos</option>
              </select>
            </div>
            <div className="form-group">
              <label className='ml-1'>Sub Categoria</label>
              <select className="custom-select" name='subCategory' onChange={this.onChange}>
                <option>Selecciona sub categoria</option>
                <option>Fishing Services</option>
                <option>Milling Services</option>
                <option>Downhole Camera</option>
                <option>Cutting and Slot Recovery</option>
                <option>Wellbore Clean Up</option>
                <option>Pulling and Running Services</option>
                <option>Drilling Impulse</option>
                <option>Whipstock Services</option>
                <option>Casing Repair & Abandoment</option>
              </select>
            </div>
            <div className="custom-file mb-5 mt-3">
              <input type="file" className="custom-file-input" id="fileFyt" onChange={this.fileChangedHandler} />
              <label className="custom-file-label" htmlFor="fileFyt">{file === null ? 'Elegir Foto' : file.name}</label>
            </div>
            <div className="custom-file mb-5 mt-3">
              <input type="file" className="custom-file-input" id="fileFytPdf" onChange={this.fileChangedHandlerPdf} />
              <label className="custom-file-label" htmlFor="fileFytPdf">{filePdf === null ? 'Elegir PDF' : filePdf.name}</label>
            </div>
          </div>
          <div className='col-sm-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">Editor para la creación de artículos.</li>
              </ol>
            </nav>
            <div className='tabs-create'>
              <div className='unline-docs'>
                <div className='unlineFlex'>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={lfarrow} alt='altleftarrow' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={rtarrow} alt='altrighttarrow' />
                  </div>
                  <div className='itemUnline'>
                    <div className="dropdown">
                      <button className="unlineDrop dropdown-toggle" 
                        type="button" id="stylesFont" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false">
                        Estilos
                      </button>
                      <div className="dropdown-menu" aria-labelledby="stylesFont">
                        <a className="dropdown-item" href="/">Action</a>
                        <a className="dropdown-item" href="/">Another action</a>
                        <a className="dropdown-item" href="/">Something else here</a>
                      </div>
                    </div>
                  </div>
                  <div className='itemUnline'>
                    <div className="dropdown">
                      <button className="unlineDrop noBorderLeft dropdown-toggle" 
                        type="button" id="headingF" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false">
                        Cabecera
                      </button>
                      <div className="dropdown-menu" aria-labelledby="headingF">
                        <a className="dropdown-item" href="/">Action</a>
                        <a className="dropdown-item" href="/">Another action</a>
                        <a className="dropdown-item" href="/">Something else here</a>
                      </div>
                    </div>
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={boldF} alt='bold' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={italicF} alt='italic' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={strickeT} alt='strikethrough' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={rFormat} alt='removeFormat' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={numberedList} alt='numbered list' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={bList} alt='bulleded list' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={dindent} alt='decrease indent' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={iIndent} alt='increase indent' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={quote} alt='block quote' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={link} alt='link' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={unlink} alt='unlink' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={image} alt='imageEdit' onClick={this.onImageControl} />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={insertM} alt='insert media embed' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={table} alt='table' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={emoji} alt='emoji' />
                  </div>
                  <div className='itemUnline'>
                    <img className='iconUnline' src={maximize} alt='maximize' />
                  </div>
                </div>
              </div>
              <div className='word-docs-fyt'>
                <div className='fyt_editor' id='fyt_editor' contentEditable></div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 mt-3 float-right'>
            {btnActive === 'on' ?
            <button className='btn float-right' style={{width:'30%'}} onClick={this.onSubmit}>Guardar</button>
            :
            <button className='btn float-right' style={{width:'30%'}} disabled>Guardando...</button>
            }
          </div>
        </div>
      </div>
    )
  }
}
