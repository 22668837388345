import React, { Component } from 'react';
import Navbar from '../../Navbar/navbar';
import Portofolio from './Portafolio/portofolio';
import Footer from '../../Navbar/footer';
import { portofolioFunction } from '../../../utils/js/main';

class newsroom extends Component {

  componentDidMount(){
    portofolioFunction();
  }

  render() {
    return (
      <div>
        <Navbar rgbcolor={{color:2}} />
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'><Portofolio /></div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default newsroom