import React, { Component } from 'react';
import bg from '../Assets/bg.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { registerUser } from '../../actions/auth';
import avatar from '../Assets/avatar.svg';
import { inputQueryLogin } from '../../utils/js/main';
import './login.css';

class register extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      email: '',
      names: '',
      phone: '',
      password: '',
      errors: {}
    }
  }

  componentDidMount(){
    inputQueryLogin();
  }

  static getDerivedStateFromProps(props, state){
    if(props.errors !== state.errors){
      return{
        errors: props.errors
      }
    }else{
      return null
    }
  }

  onChangeRegister = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmitRegister = (e) => {
    e.preventDefault()

    const newUser = {
      username: this.state.username,
      email: this.state.email,
      names: this.state.names,
      phone: this.state.phone,
      password: this.state.password
    }

    this.props.registerUser(newUser, this.props.history);

  }

  render() {
    const { errors } = this.state;
    return (
      <div className="containerLogin">
		    <div className="img">
		    	<img src={bg} alt='img1' />
		    </div>
		    <div className="login-content">
		    	<form onSubmit={this.onSubmitRegister}>
		    		<img src={avatar} alt='img2' />
		    		<h2 className="title">Register</h2>
            <div className="input-div one">
              <div className="i">
              	<i className="fas fa-user"></i>
              </div>
              <div className="div">
              	<h5>Username</h5>
                <input type="text" 
                className={classnames('inputLogin', {' is-invalid-input': errors.username})}
                name='username' onChange={this.onChangeRegister} />
              </div>
              {errors.username && (
                <div style={{textAlign:'right',width:'97%',fontSize:'12px'}}>
                  <span style={{color:'red'}}>{errors.username}</span>
                </div>
              )}
              </div>
            <div className="input-div one">
              <div className="i">
              	<i className="fas fa-user"></i>
              </div>
              <div className="div">
              	<h5>Nombres</h5>
                <input type="text" 
                className={'inputLogin'}
                name='names' onChange={this.onChangeRegister} />
              </div>
            </div>
            <div className="input-div one">
              <div className="i">
              	<i className="fas fa-user"></i>
              </div>
              <div className="div">
              	<h5>Email</h5>
                <input type="text" 
                className={'inputLogin'}
                name='email' onChange={this.onChangeRegister} />
              </div>
            </div>
            <div className="input-div one">
              <div className="i">
              	<i className="fas fa-user"></i>
              </div>
              <div className="div">
              	<h5>Telefono</h5>
                <input type="text" 
                className={'inputLogin'}
                name='phone' onChange={this.onChangeRegister} />
              </div>
            </div>
            <div className="input-div pass">
              <div className="i"> 
                <i className="fas fa-lock"></i>
              </div>
              <div className="div">
                <h5>Password</h5>
                <input type="password" name='password'
                className='inputLogin'
                onChange={this.onChangeRegister} />
              </div>
            </div>
            <input type="submit" className="btn" value="Login" />
          </form>
        </div>
      </div>
    )
  }
}

register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  errors: state.errors
});

export default connect(mapStateToProps, {registerUser})(register);