import React, { Component } from 'react';
import bel from '../../Assets/logobel.png';
import halli from '../../Assets/logohal.png';
import pañ from '../../Assets/Logospañ.png';
import pan from '../../Assets/PAM.jpg';

export default class clients extends Component {
  render() {
    return (
      <section id="team">
        <div className="container wow fadeInUp">
          <div className="section-header">
            <h3 className="section-title">Clientes</h3>
          </div>
          <div className="row mt-5">    
            <div className="col-lg-3 col-md-6">
              <a className="nav-link text-secondary shadow border" rel="noopener noreferrer"
              href="https://www.petroamazonas.gob.ec/" target={'_blank'}>
                <img src={pan} alt="gtech" height="110" width="220"/>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <a className="nav-link text-secondary shadow border" rel="noopener noreferrer"
              href="https://www.halliburton.com/en-US/default.html" target={'_blank'}>
                <img src={halli} alt="gtech" height="110" width="220"/>
              </a>
            </div>    
            <div className="col-lg-3 col-md-6">
              <a className="nav-link text-secondary shadow border" rel="noopener noreferrer"
              href="https://www.emis.com/php/company-profile/EC/Servicios_Integrados_Panaturi_SA_en_4139570.html" target={'_blank'}>
                <img src={pañ} alt="gtech" height="110" width="220"/>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <a className="nav-link text-secondary shadow border" rel="noopener noreferrer"
              href="https://www.belorusneft.by/sitebeloil/en/index.html" target={'_blank'}>
                <img src={bel} alt="gtech" height="110" width="220"/>
              </a>
            </div>
          </div>    
        </div>
      </section>
    )
  }
}
