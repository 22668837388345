import { SET_CURRENT_USER, GET_ERRORS, CLEAR_CURRENT_PROFILE, PROFILE_LOADING, GET_PROFILE } from './types';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

//Register redux action
export const registerUser = (userData, history) => dispatch => {
  axios
    .post('/api/auth/user/registration', userData)
    .then(res => {
      if(res.status === 200){
        history.push('/fyt/login');
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

//Login current user
export const loginUser = userDataLogin => dispatch => {
  axios
    .post('/api/auth/user/login', userDataLogin)
    .then(res => {
      const { token } = res.data;
      localStorage.setItem('CookieLogin', token);
      setAuthToken(token);
      const decode = jwt_decode(token);
      dispatch(setCurrentUser(decode));
    })
    .catch(err => 
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}
//GET CURRENT PROFILE
export const getProfile = () => dispatch => {
  dispatch(setProfileLoading());
  axios
    .get('/api/auth/user/profile')
    .then(res => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_PROFILE,
        payload: {}
      })
    })
}

//SET LOADING
export const setProfileLoading = () => {
  return{
    type: PROFILE_LOADING
  };
};

// set current user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

//Log user out
export const logoutUser = () => dispatch => {
  localStorage.removeItem('CookieLogin');
  setAuthToken(false);
  dispatch(setCurrentUser({}))
}

//CLEAR PROFILE
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};