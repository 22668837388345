import React, { Component } from 'react';
import axios from 'axios';
import {backendurl} from '../../../common/backendURL';

export default class portafolioH extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sheets: []
    }
  }

  componentDidMount(){
    axios.get('/api/blog/show/blogs')
      .then(res => {
        var artArr = [];
        res.data.forEach(b => {
          if(b.category === 'Papeles tecnicos'){
            artArr.push(b)
          }
        });
        this.setState({
          sheets: artArr
        })
      })
      .catch(err => {console.log(err)})
  }

  render() {
    const { sheets } = this.state;
    return (
      <div style={{margin:'12% 0'}}>
        <div className='row'>
          <div className='col-sm-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{fontFamily:'WalkBold'}}>
                <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                <li className="breadcrumb-item"><a href="/">Compañia</a></li>
                <li className="breadcrumb-item active" aria-current="page">Técnicos</li>
              </ol>
            </nav>
          </div>
          <div className='col-sm-12'>
            <section id="portfolio">
              <div className="container wow fadeInUp">
                <div className="section-header">
                  <h6 className="section-title mb-5" style={{fontFamily:'WalkBold'}}>INFORMACIÓN TÉCNICA</h6>
                </div>
              </div>
              <div className="row" id="portfolio-wrapper">
                  {sheets.map((art, i) => 
                  <div className={`col-lg-3 col-md-6 portfolio-item`} key={i}>
                    <a href={`/fyt/news/${art._id}`}>
                      <img src={backendurl+art.file} alt="imagen-blog" />
                      <div className="details">
                        <h4>{art.title}</h4>
                        <span>{art.description}</span>
                        <span>{art.date}</span>
                      </div>
                    </a>
                  </div>   
                  )}        
                </div> 
            </section>
          </div>
        </div>
      </div>
    )
  }
}