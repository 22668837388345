import React, { Component } from 'react';
import Navbar from '../Navbar/navbar';
import './home.css';
import { Carousel } from 'react-bootstrap';
import AboutUs from './templates/aboutus';
import Services from './templates/services';
import Team from './templates/team';
import Client from './templates/clients';
import ContactUs from './templates/contactus';
import Footer from '../Navbar/footer';

//photos
import s1 from '../Assets/1.jpg';
import s2 from '../Assets/2.jpg';
import s3 from '../Assets/3.jpg';
import s4 from '../Assets/4.jpg';
import s5 from '../Assets/5.jpg';
import s6 from '../Assets/petro3.png';
import logoInit from '../Assets/homeLogo.png';

class Home extends Component {
  componentDidMount(){
    var ani = document.getElementById('animationHome');
    setTimeout(() => {
      ani.style.display = 'none';
    }, 3000);
  }
  render() {
    return (
      <div>
        <div className='animationHome' id='animationHome'>
          <img src={logoInit} width="100%" alt='homeLogo' />
        </div>
        <Navbar rgbcolor={{color:1, path:this.props.match.path}} />
        <section id="hero" style={{overflowX:'hidden'}}>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s6}
                height='100%'
                alt="First slide"
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    FISHING SERVICES</h6>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s1}
                alt="Third slide"
                height='100%'
                style={{width:'100%', height:'100%'}}
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    MILLING SERVICES</h6>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s2}
                height='100%'
                alt="Third slide"
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    DOWNHOLE CAMERA</h6>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s3}
                height='100%'
                alt="Third slide"
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    CUTTING & SLOT RECOVERY</h6>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s4}
                height='100%'
                alt="Third slide"
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    WELLBORE CLEAN UP</h6>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={s5}
                height='100%'
                alt="Third slide"
              />
              <div className='captionS'>
                <div className='titleS'>
                  <h6 style={{width:'50%',fontSize:'42px',textAlign:'right'}}>
                    SOMOS F&T</h6>
                  <h6 style={{width:'50%',fontSize:'42px', textAlign:'right'}}>
                    INTERVENCIÓN EFECTIVA</h6>
                </div>
                <div className='cap'>
                  <h6 style={{width:'70%',fontSize:'42px', textAlign:'center'}}>
                    PULLING & RUNNING SERVICES</h6>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
        <AboutUs />
        <section id="facts">
          <div className="container wow fadeIn">
            <div className="section-header">
              <h3 className="section-title" style={{fontFamily:'WalkBold'}}>Indicadores</h3>
              <p className="section-description">Efectividad comprobada</p>
            </div>
            <div className="row counters">      
  	      		<div className="col-lg-3 col-6 text-center">
                <span className='cUp' data-toggle="counter-up" 
                style={{fontSize:'25px', fontFamily:'WalkBold',textTransform:'uppercase'}}>88 Intervenciones</span>
                <p>Trabajos Realizados</p>
  	      		</div>      
              <div className="col-lg-3 col-6 text-center">
                <span className='cUp' data-toggle="counter-up" 
                style={{fontSize:'25px', fontFamily:'WalkBold',textTransform:'uppercase'}}>98,864%</span>
                <p>Eficiencia Operativa</p>
  	      		</div>      
              <div className="col-lg-3 col-6 text-center">
                <span className='cUp' data-toggle="counter-up" 
                style={{fontSize:'25px', fontFamily:'WalkBold',textTransform:'uppercase'}}>2832,5 HORAS</span>
                <p>Soporte Técnico</p>
  	      		</div>      
              <div className="col-lg-3 col-6 text-center">
                <span className='cUp' data-toggle="counter-up" 
                style={{fontSize:'25px', fontFamily:'WalkBold',textTransform:'uppercase'}}>220 BHA’s</span>
                <p>Ensambles Realizados</p>
  	      		</div>      
  	      	</div>      
          </div>
        </section>
        <Services />
        <Team />
        <Client />
        <ContactUs />
        <Footer />
      </div>
    )
  }
}

export default Home