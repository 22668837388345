import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import bg from '../Assets/bg.svg';
import avatar from '../Assets/avatar.svg';
import { inputQueryLogin } from '../../utils/js/main';
import './login.css';
import { loginUser } from '../../actions/auth';

class login extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      password: '',
      isAuthenticated: false,
      errors: {}
    }
  }

  componentDidMount(){
    if(this.props.auth.isAuthenticated){
      this.props.history.push('/')
    }
    inputQueryLogin();
  }

  static getDerivedStateFromProps(nextProps, state){
    if(nextProps.auth.isAuthenticated !== state.isAuthenticated){
      nextProps.history.push('/')
    }

    if(nextProps.errors !== state.errors){
      return{
        errors: nextProps.errors
      }
    }

    return null
  }

  onHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmitLogin = (e) => {
    e.preventDefault();
    const userLogin = {
      name: this.state.name,
      password: this.state.password
    }
    this.props.loginUser(userLogin)
  }

  render() {
    const {errors} = this.state;
    return (
      <div className="containerLogin">
		    <div className="img">
		    	<img src={bg} alt='img1' />
		    </div>
		    <div className="login-content">
		    	<form onSubmit={this.onSubmitLogin}>
		    		<img src={avatar} alt='img2' />
		    		<h2 className="title">Bienvenidos</h2>
            <div className={"input-div one" + classnames({' is-invalid-input': errors.credential})}>
              <div className="i">
              	<i className="fas fa-user"></i>
              </div>
              <div className="div">
              	<h5>Usuario</h5>
                <input type="text" 
                className='inputLogin'
                name='name' onChange={this.onHandleChange} />
              </div>
            </div>
            {errors.credential && (
              <div style={{textAlign:'right',width:'97%',fontSize:'12px'}}>
                <span style={{color:'red'}}>{errors.credential}</span>
              </div>
            )}
            <div className="input-div pass">
              <div className="i"> 
                <i className="fas fa-lock"></i>
              </div>
              <div className="div">
                <h5>Contraseña</h5>
                <input type="password" 
                className={'inputLogin' + classnames({' is-invalid-input': errors.password})}
                name='password' onChange={this.onHandleChange} />
              </div>
            </div>
            {errors.password && (
              <div style={{textAlign:'right',width:'97%',fontSize:'12px'}}>
                <span style={{color:'red'}}>{errors.password}</span>
              </div>
            )}
            <input type="submit" className="btn" value="Login" />
          </form>
        </div>
      </div>
    )
  }
}

login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {loginUser})(login)