import React, { Component } from 'react';
import axios from 'axios';
import { backendurl } from '../../common/backendURL';
import Navbar from '../Navbar/navbar';

class showBlog extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      blog: []
    }
  }
  
  componentDidMount(){
    axios.get(`/api/blog/show/blog/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          blog: res.data
        })
      })
      .catch(err => {console.log(err)})
  }
  render() {
    const { blog } = this.state;
    if(blog.blog !== undefined){
      document.getElementById('editorText').innerHTML = blog.blog
    }
    return (
      <div>
        <Navbar rgbcolor={{color:2}} />
        <div style={{marginTop:'15%'}}>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <a style={{color:'red'}} href={backendurl + blog.filePdf} target="blank" rel="noopener noreferrer">
                  <i className="fas fa-file-pdf"></i> Archivo PDF
                </a>
              </div>
              <div className='col-sm-12 mt-4'>
                <div id='editorText'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default showBlog;