import React, { Component } from 'react';
import Navbar from '../../Navbar/navbar';
import PortofolioH from './portafolioH';
import Footer from '../../Navbar/footer';

class energy extends Component {
  render() {
    return (
      <div>
        <Navbar rgbcolor={{color:2}} />
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'><PortofolioH /></div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default energy