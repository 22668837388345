import React, { Component } from 'react';
import fs from '../../Assets/svgServices/Fishing_Services.svg';
import ms from '../../Assets/svgServices/Milling_Services.svg';
import dhc from '../../Assets/svgServices/DHC_Services.svg';
import csr from '../../Assets/svgServices/Cutting_Services.svg';
import wbcu from '../../Assets/svgServices/WBCU_Services.svg';
import prs from '../../Assets/svgServices/Pulling_Running_Services.svg';
import di from '../../Assets/svgServices/Drilling_Impulse.svg';
import ws from '../../Assets/svgServices/Re-entry_Services.svg';
import cra from '../../Assets/svgServices/Casing_Repair_Abandonment.svg';

export default class services extends Component {
  render() {
    return (
      <section id="services">
        <div className="container wow fadeIn">
          <div className="section-header">
            <h3 className="section-title" style={{fontFamily:'WalkBold'}}>Portafolio de Servicios</h3>
            <p className="section-description" style={{fontFamily:'WalkBold'}}>INTERVENCIÓN EFECTIVA</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={fs} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Fishing Services</a></h4>
                <p className="description">
                  Brindamos un servicio completo
                  de pesca, personal técnico con
                  experiencia y herramientas de la
                  mejor calidad.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={ms} alt='fishing Services' style={{width:'60%'}} />  
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Milling Services</a></h4>
                <p className="description">
                  Diseñamos y Construimos
                  herramientas para todo tipo de
                  molienda ya sea para
                  acondicionamiento o trabajos más
                  severos.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={dhc} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Down Hole Camera</a></h4>
                <p className="description">
                  Con la ayuda de nuestra cámara se
                  puede observar y detectar el
                  estado del casing, colapsos y
                  equipos perdidos en el pozo.
                </p>
              </div>
            </div>    
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={csr} alt='fishing Services' style={{width:'60%'}} />  
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Cutting and Slot Recovery</a></h4>
                <p className="description">
                  Tenemos opciones para corte de
                  tubing y casing, ya sean
                  herramientas de accionamiento
                  hidráulico o mecánico, y corte por
                  interno o externo.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={wbcu} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Wellbore Clean Up</a></h4>
                <p className="description">
                  Tenemos en stock herramientas
                  para limpieza mecánica de pozos,
                  contamos con nuestro sistema de
                  circulación inversa, raspadores,
                  cepillos, magnetos y canastas.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={prs} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Pulling and Running Services</a></h4>
                <p className="description">
                  Con nuestro sistema de
                  Pulling Jack podemos levantar
                  pesos que normalmente un
                  taladro de workover no
                  resiste.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={di} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Drilling Impulse</a></h4>
                <p className="description">
                  Reducimos la fricción de la sarta e impulsamos el
                  bha ya sea de perforación para un mejor
                  deslizamiento y transmitir tensión y peso
                  efectivamente.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={ws} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">Whipstock Services</a></h4>
                <p className="description">
                  Nuestros sistemas permiten abrir ventanas de
                  calibre completo en un solo viaje, en diferentes
                  formaciones y condiciones de pozo.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="box">
                <div className="icon"><a href="/fyt/newsroom">
                  <img src={cra} alt='fishing Services' style={{width:'60%'}} />
                </a></div>
                <h4 className="title"><a href="/fyt/newsroom">CASING REPAIR & ABANDONMENT</a></h4>
                <p className="description">
                  Tenemos herramientas y técnicas de reparación de
                  casing, y abandono de pozos, salvaguardando el
                  medioambiente y concluyendo la vida útil de un
                  pozo.
                </p>
              </div>
            </div>
          </div>    
        </div>
      </section>
    )
  }
}
