import React, { Component } from 'react'

export default class footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div className="footer-top">
          <div className="container">

          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong>F&T Servicios Petroleros</strong>. All Rights Reserved
          </div>
          <div className="credits">
            <a href="https://www.shopperec.com/">Bash</a>
          </div>
        </div>
      </footer>
    )
  }
}
