import React, { Component } from 'react';
//import data from './test.json';
import axios from 'axios';
import {backendurl} from '../../../../common/backendURL';

export default class portofolio extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      art: []
    }
  }

  componentDidMount(){
    axios.get('/api/blog/show/blogs')
      .then(res => {
        var artArr = [];
        res.data.forEach(b => {
          if(b.category === 'Articulo'){
            artArr.push(b)
          }
        });
        this.setState({
          art: artArr
        })
      })
      .catch(err => {console.log(err)})
  }
  
  render() {
    const { art } = this.state;
    return (
      <div style={{margin:'18% 0'}}>
        <div className='row'>
          <div className='col-sm-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{fontFamily:'WalkBold'}}>
                <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                <li className="breadcrumb-item"><a href="/">Compañia</a></li>
                <li className="breadcrumb-item active" aria-current="page">Artículos</li>
              </ol>
            </nav>
          </div>
          <div className='col-sm-12'>
            <section id="portfolio">
              <div className="container wow fadeInUp">
                <div className="section-header">
                  <h6 className="section-title mb-5" style={{fontFamily:'WalkBold'}}>Nuestra experiencia</h6>
                </div>
                <div className="row">         
                  <div className="col-lg-12">
                    <ul id="portfolio-flters">
                      <li data-filter=".Fishing-Services, .Milling-Services, .Down-Hole-Camera, .Cutting-and-Slot-Recovery, .Wellbore-Clean-Up, .Pulling-and-Running-Services, .Drilling-Impulse, .Whipstock-Services, .Casing-repair"
                       className="filter-active">Todos</li>
                      <li data-filter=".Fishing-Services">Fishing Services</li>
                      <li data-filter=".Milling-Services">Milling Services</li>
                      <li data-filter=".Down-Hole-Camera">DownHole Camera</li>
                      <li data-filter=".Cutting-and-Slot-Recovery">Cutting & Slot Recovery</li>
                      <li data-filter=".Wellbore-Clean-Up">Wellbore Clean Up</li>
                      <li data-filter=".Pulling-and-Running-Services">pulling & running services</li>
                      <li data-filter=".Drilling-Impulse">Drilling Impulse</li>
                      <li data-filter=".Whipstock-Services">Whipstock Services</li>
                      <li data-filter=".Casing-repair">Casing Repair & Abandonment</li>
                    </ul>
                  </div>
                </div>          
                <div className="row" id="portfolio-wrapper">
                  {art.map((art, i) => 
                  <div className={`col-lg-3 col-md-6 portfolio-item ${art.subcategory.replace(" ","-")}`} key={i}>
                    <a href={`/fyt/news/${art._id}`}>
                      <img src={backendurl+art.file} alt="imagen-blog" />
                      <div className="details">
                        <h4>{art.title}</h4>
                        <span>{art.description}</span>
                        <span>{art.date}</span>
                      </div>
                    </a>
                  </div>   
                  )}        
                </div>          
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}
