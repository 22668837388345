import React, { Component } from 'react';
import jose from '../../Assets/j.jpg';
import vio from '../../Assets/v.jpg';
import yol from '../../Assets/yola.jpg';

export default class team extends Component {
  render() {
    return (
      <section id="team">
        <div className="container wow fadeInUp">
          <div className="section-header">
            <h3 className="section-title" style={{fontFamily:'WalkBold'}}>Equipo</h3>
            <p className="section-description">Nuestro equipo representativo te dará el mejor soporte técnico </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="member">
                <div className="pic"><img src={jose} alt="img1" width='100%' /></div>
                <h4>Jose Ortiz</h4>
                <span>Operations Manager</span>
                <span>0993434954</span>
                <span>jose.ortiz@fyt.ec</span>
                <div className="social">
                  <a href="https://www.linkedin.com/in/jose-ortiz-13692aa7/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i></a>
                </div>
              </div>
            </div>    
            <div className="col-lg-4 col-md-6">
              <div className="member">
                <div className="pic"><img src={yol} alt="img2" width='100%' /></div>
                <h4>Yolanda Cedeño</h4>
                <span>Gerente financiero y administrativo</span>
                <span>0997288082</span>
                <span>yolanda.cedeno@fyt.ec</span>
                <div className="social">
                  <a href="https://www.linkedin.com/in/yolanda-cede%C3%B1o-3b62611a8/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i></a>
                </div>
              </div>
            </div>    
            <div className="col-lg-4 col-md-6">
              <div className="member">
                <div className="pic"><img src={vio} alt="img3" width='100%' /></div>
                <h4>Violeta Ortiz</h4>
                <span>Principal Application</span>
                <span>0985347055</span>
                <span>violeta.ortiz@fyt.ec</span>
                <div className="social">
                  <a href="https://www.linkedin.com/in/violeta-ortiz-46176717a/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>    
        </div>
      </section>
    )
  }
}
