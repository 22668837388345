import React, { Component } from 'react';

export default class userProfile extends Component {
  render() {
    return (
      <div className='container2'>
        <div className='row'>
          <div className='col-sm-12 mt-5'>
            <div className='card' style={{width:'100%'}}>
              <div className='card-header card-header-primary' style={{background:'rgba(0, 0, 2, 0.806)'}}>
                <h6 className='card-title' style={{color:'white'}}>Editar Perfil</h6>
                <p className='card-category' style={{color:'white'}}>Completa tu perfil o agregar nuevas cosas</p>
              </div>
              <div className='card-body' >
                <form style={{width:'100%'}}>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className="form-group" >
                        <label htmlFor="inputEmail" style={{fontSize:'12px',paddingLeft:'1%'}}>Dirección de email</label>
                        <input type="email" className="form-control" id="inputEmail"
                        placeholder="Ingresar email" />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className="form-group" >
                        <label htmlFor="inputUserName" style={{fontSize:'12px',paddingLeft:'1%'}}>Usuario</label>
                        <input className="form-control" id="inputUserName" placeholder="Usuario" />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className="form-group" >
                        <label htmlFor="inputName" style={{fontSize:'12px',paddingLeft:'1%'}}>Nombres</label>
                        <input className="form-control" id="inputName" placeholder="Ingresa tu nombre completo" />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className="form-group" >
                        <label htmlFor="inputPhone" style={{fontSize:'12px',paddingLeft:'1%'}}>Telefono</label>
                        <input className="form-control" id="inputPhone" placeholder="Ingresa telefono" />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div className="form-group" >
                        <label htmlFor="inputPassword" style={{fontSize:'12px',paddingLeft:'1%'}}>Contraseña</label>
                        <input className="form-control" id="inputPassword" placeholder="Ingresa tu nueva contraseña" />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <button type='submit' className='btn'>Guardar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
