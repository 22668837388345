import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfile, logoutUser } from '../../actions/auth';
import './profile.css';
import { profileNav } from '../../utils/js/main';
import UserProfile from './tabs/userProfile';
import CreateItem from './tabs/createItem';

class profile extends Component {

  componentDidMount(){
    if(!this.props.auth.isAuthenticated){
      this.props.history.push('/')
    }
    profileNav();
  }

  logOut = (e) => {
    e.preventDefault();
    this.props.logoutUser();
    window.location.href = '/';
  }

  render() {
    return (
      <div className='containerProfile'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='row'>
              <div className='p-0' style={{width:'265px'}}>
                <div className='menuProfile'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='logoPro'>
                          <a href='/' className='simple-text'>Fishing and Tools</a>
                        </div>
                      </div>
                      <div className='col-sm-12 p-0'>
                        <div className='sidebar-wrapper'>
                          <ul className='navPro'>
                            <li className='navItemPro' data='UserProfile'>
                              <a href='/' className='navLink activeItem' id='userProfile' onClick={(e) => e.preventDefault()}>
                                <i className="fas fa-user"></i>
                                <p>Perfil de usuario</p>
                              </a>
                            </li>
                            <li className='navItemPro' data='createItem'>
                              <a href='/' className='navLink' id='createItemId' onClick={(e) => e.preventDefault()}>
                                <i className="fas fa-pencil-alt"></i>
                                <p>Crear Artículo</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='dashInfo pl-0 pr-0'>
                <div className='navProfile'>
                  <div className='container-fluid'>
                    <div className='TitleLogo' id='TitleLogo'>
                      <i className="fas fa-tachometer-alt"></i> Dashboard
                    </div>
                    <div className='NavCollapse'>
                      <div>
                        <ul className='navbar-profile-ul'>
                          <li>
                            <i className='fas fa-user-circle nav-link' id="navbarDropdown" style={{cursor:'pointer'}}
                            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a className="dropdown-item" href="/">Menu principal</a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="/" onClick={this.logOut} style={{color:'red'}}>Cerrar sesión</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position:"relative"}}>
                  <div className='UserProfile' id='UserProfile'>
                    <UserProfile />
                  </div>
                  <div className='createItem' id='createItem'>
                    <CreateItem />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

profile.propTypes = {
  getProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, {getProfile, logoutUser})(profile)