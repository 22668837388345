import React, { Component } from 'react';

export default class aboutus extends Component {
  render() {
    return (
      <section id="about">
        <div className="container">
          <div className="row about-container">
            <div className="col-lg-6 content order-lg-1 order-2">
              <h2 className="title" style={{fontFamily:'WalkBold'}}>Nuestra vocación</h2>
              <p>
                Somos una compañía dedicada a
                proponer y aplicar soluciones para
                intervención de pozos, nuestro personal y
                herramientas están disponibles 24/7 para
                nuestros clientes.
              </p>
              <div className="icon-box">
                <div className="icon"><i className="fa fa-rocket"></i></div>
                <h4 className="title"><a href="/" style={{fontFamily:'WalkBold'}}>Nuestra Misión</a></h4>
                <p className="description">“Servir a nuestros clientes del Ecuador y
                    la región, con la mejor tecnología,
                    diversidad de equipos, personal
                    capacitado y de alta experiencia, para
                    ejecutar una operación segura, efectiva y
                    exitosa”
                </p>
              </div>
              <div className="icon-box">
                <div className="icon"><i className="fas fa-eye"></i></div>
                <h4 className="title"><a href="/" style={{fontFamily:'WalkBold'}}>Nuestra Visión</a></h4>
                <p className="description">
                “Ser reconocidos por nuestros clientes,
                  inversionistas y trabajadores como la
                  empresa de pesca de mayor prestigio en
                  el Ecuador y la región”
                </p>
              </div>
              <div className="icon-box">
                <div className="icon"><i className="fa fa-hands"></i></div>
                <h4 className="title"><a href="/" style={{fontFamily:'WalkBold'}}>Nuestros Valores</a></h4>
                <p className="description">
                  Competitividad,
                  Confianza,
                  Integridad.
                </p>
              </div>
            </div>
            <div className="col-lg-6 background order-lg-2 order-1" id='picAboutUs'></div>
          </div>
        </div>
      </section>
    )
  }
}
