import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  setCurrentUser,
  logoutUser,
  clearCurrentProfile,
  getProfile
} from './actions/auth';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import store from './store';
import './App.css';

//routes
import Home from './components/Dashboard/Home';
import Profile from './components/Profile/profile';
import Newsroom from './components/Blogs/Newsroom/newsroom';
import Login from './components/Auth/login';
import Register from './components/Auth/register';
import Energy from './components/Blogs/Energy/energy';
import Sheets from './components/Blogs/TechSheets/sheets';
import ShowProduct from './components/Blogs/showBlog';

//check for token
if(localStorage.CookieLogin){
  setAuthToken(localStorage.CookieLogin);
  const decoded = jwt_decode(localStorage.CookieLogin);
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(getProfile());

  //check for expire in
  const currentTime = Date.now() / 1000;
  if(decoded.exp < currentTime){
    store.dispatch(logoutUser());
    store.dispatch(clearCurrentProfile());
    window.location = '/';
  }
}

function App() {
  return (
    <Provider store={ store }>
      <Router>
        <div className="App">
          <Route exact path='/' component={ Home } />
          <Route exact path='/fyt/newsroom' component={ Newsroom } />
          <Route exact path='/fyt/energy' component={ Energy } />
          <Route exact path='/fyt/sheets' component={ Sheets } />
          <Route exact path='/fyt/news/:id' component={ ShowProduct } />
          <Route exact path='/fyt/profile' component={ Profile } />
          <Route exact path='/fyt/login' component={ Login } />
          <Route exact path='/fyt/register' component={ Register } />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
